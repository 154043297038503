import { HotelsDisneySearchFormSubmitInterface } from '@smartlinks/react-design-system/dist/src/lib/hotels-disney/shared/interfaces/hotels-disney-search-form.interface';
import { HotelsDisneySearchFormUtil } from '@smartlinks/react-design-system/dist/src/lib/hotels-disney/shared/utils/hotels-disney-search-form.util';
import { AddonsInterface } from '@smartlinks/react-design-system/dist/src/shared/interfaces/hotels/addons.interface';
import { HotelDisneyAddonsRequest } from '../interfaces/hotel-disnay-addons-request.interface';
import { ResponseCommons } from '../../shared/interfaces/commons.interface';
import { LocalStorageHistoriesNames } from '../enums/local-storage.enum';
import { ResponseHttp } from '../interfaces/response-http.interface';
import { endPoints } from '../end-points/end-points';
import { CommonsService } from './commons.service';

export class HotelsDisneyService extends CommonsService {
	constructor(token: string) {
		super(token, LocalStorageHistoriesNames.hotelsDisneySearch);
	}

	public getHotelsDisneySearchFormHistory(traductions?: (label: string) => string): HotelsDisneySearchFormSubmitInterface | null {
		const tempHistory: ResponseCommons<HotelsDisneySearchFormSubmitInterface> = this.getHistory<HotelsDisneySearchFormSubmitInterface>(
			HotelsDisneySearchFormUtil.historyHandle,
			traductions,
		);

		return tempHistory?.response || null;
	}

	public async getAddons(data: HotelDisneyAddonsRequest): Promise<AddonsInterface[] | undefined> {
		const url: string = `${this.searchApi}/${endPoints.hotelDisneyAddons}`;
		const addonsResponse: ResponseHttp<AddonsInterface[]> = await this.httpCall.post(url, data, this.header);
		if (addonsResponse.response != null) {
			return addonsResponse.response;
		}

		return undefined;
	}
}
