import { DisneySearchFormSubmitInterface } from '@smartlinks/react-design-system/dist/src/lib/disney/shared/interfaces/disney-search-form.interface';
import { DisneySearchFormUtil } from '@smartlinks/react-design-system/dist/src/lib/disney/shared/utils/disney-search-form.util';
import { LocalStorageHistoriesNames } from '../enums/local-storage.enum';
import { ResponseCommons } from '../interfaces/commons.interface';
import { CommonsService } from './commons.service';

export class DisneyService extends CommonsService {
	constructor(token: string, customHistoryName?: LocalStorageHistoriesNames) {
		super(token, customHistoryName || LocalStorageHistoriesNames.disneySearch);
	}

	public getDisneySearchFormHistory(traductions?: (label: string) => string): DisneySearchFormSubmitInterface | null {
		const tempHistory: ResponseCommons<DisneySearchFormSubmitInterface> = this.getHistory<DisneySearchFormSubmitInterface>(
			DisneySearchFormUtil.historyHandle,
			traductions,
		);

		return tempHistory?.response || null;
	}
}
