import {
	HotelsSearchFormSubmitInterface,
	RoomsFieldsRestrictionsInterface,
} from '@smartlinks/react-design-system/dist/src/lib/hotels/shared/interfaces/hotels-search-form.interface';
import { PassengersFieldRestrictionsInterface } from '@smartlinks/react-design-system/dist/src/shared/interfaces/generics/search-fields.interface';
import { HotelsSearchFormUtil } from '@smartlinks/react-design-system/dist/src/lib/hotels/shared/utils/hotels-search-form.util';
import { ListInterface, ListItemInterface } from '@smartlinks/react-design-system/dist/src/shared/interfaces/list.interface';
import { HotelsSearchLocationsInterface } from '../../shared/interfaces/hotels-locations.interface';
import { HotelsLocalStorageInterface } from '../../shared/interfaces/widget-localstorage.interface';
import { Spinner } from '@smartlinks/react-design-system/dist/src/lib/ui-elements/spinner/spinner';
import { useDebounce } from '@smartlinks/react-design-system/dist/src/shared/hooks/useDebounce';
import { HotelsSearchWidgetProps } from '../../widgets/hotels-search/hotelsSearchWidget';
import { TransformedCountries } from '../../shared/interfaces/countries.interface';
import { useState, useCallback, useMemo, useEffect, lazy, Suspense } from 'react';
import { HotelProvider } from '../../shared/enums/hotel-provider.enum';
import { HotelsService } from '../../shared/services/hotels.service';
import { HotelsUtil } from '../../shared/utils/hotels-search.util';
import useCountriesFrom from '../../shared/hooks/useCountriesFrom';
import { Agency } from '../../shared/interfaces/agency.interface';
import { endPoints } from '../../shared/end-points/end-points';
import { TFunction } from 'react-i18next';

const HotelsSearchForm = lazy(async () => {
	return await import('@smartlinks/react-design-system/dist/src/lib/hotels/organisms/hotels-search-form/hotels-search-form').then(module => {
		return { default: module.HotelsSearchForm };
	});
});

interface HotelsSearchTemplateProps extends HotelsSearchWidgetProps {
	hotelsService: HotelsService;
	t: TFunction<'translation', undefined>;
}
function HotelsSearch({
	agency,
	useExternalRedirect,
	hotelsService,
	t,
	callUrlSearch,
	callShowMobileFields,
}: HotelsSearchTemplateProps): JSX.Element {
	const locationsDebounceHandler = useDebounce();
	const [isLocationsLoading, setIsLocationsLoading] = useState<boolean>(false);
	const [history, setHistory] = useState<HotelsSearchFormSubmitInterface | null>(null);
	const { countriesList } = useCountriesFrom(hotelsService);
	const [locations, setLocations] = useState<ListInterface[]>([
		{
			title: '',
			items: [],
		},
	]);

	const maxRoomsRestrictions: number = parseInt(agency?.widgets?.hotelsCriteriaConfiguration?.maxRooms || '2', 10);
	const maxGuestsPerRoom: number = parseInt(agency?.widgets?.hotelsCriteriaConfiguration?.maxGuests || '9', 10);
	const maxAdults: number = maxRoomsRestrictions * maxGuestsPerRoom;
	const maxChildren: number = maxRoomsRestrictions * maxGuestsPerRoom;
	const nationality: string = agency?.profile.countryCode || '';
	const defaultNationality: TransformedCountries | undefined = countriesList.find(country => country.value === nationality);
	const peopleByRoomRestrictions: PassengersFieldRestrictionsInterface = useMemo(
		() =>
			((tempMaxAdults: number, tempMaxChildren: number): PassengersFieldRestrictionsInterface => {
				return HotelsUtil.peopleByRoomRestrictions(tempMaxAdults, tempMaxChildren);
			})(maxAdults, maxChildren),
		[maxAdults, maxChildren],
	);

	const roomsRestrictions: RoomsFieldsRestrictionsInterface = useMemo(
		() =>
			((tempMaxAdults: number, tempMaxChildren: number): RoomsFieldsRestrictionsInterface => {
				return {
					children: tempMaxChildren,
					adults: tempMaxAdults,
				};
			})(maxAdults, maxChildren),
		[maxAdults, maxChildren],
	);

	const calendarRestriction: number = useMemo(
		() =>
			((tempAgency?: Agency | null): number => {
				return HotelsUtil.calendarRestriction(tempAgency);
			})(agency),
		[agency],
	);

	const searchHandler = useCallback(
		(data: HotelsSearchFormSubmitInterface): void => {
			const dataForm: HotelsSearchFormSubmitInterface = {
				...data,
				urlPath: HotelsSearchFormUtil.mapSearchUrl(data),
			};

			const dataLocalStorage: HotelsLocalStorageInterface = {
				destination: data.destination,
				destinationIata: data.destinationIata,
				locationKey: data.locationKey ?? '',
			};

			if (useExternalRedirect) {
				const agencyDomainToRedirect: string = agency?.profile?.domainUrl || '';
				const createAnchor: HTMLAnchorElement = document.createElement('a');
				createAnchor.target = '_blank';

				createAnchor.href = endPoints.flightsSearch(agencyDomainToRedirect, dataForm?.urlPath || '');

				createAnchor.click();

				return;
			}

			if (callUrlSearch != null) {
				callUrlSearch(dataForm);
			}

			hotelsService.setLocalHistory(dataLocalStorage);
		},
		[hotelsService, useExternalRedirect, agency, callUrlSearch],
	);

	const callLocationsByWord = useCallback(
		(locationName: string): void => {
			const isSecondaryTemplate: boolean = !!agency?.widgetStyles?.isSecondaryTemplate;
			const isEnabledTruncatedInputs: boolean = !!agency?.widgetStyles?.isEnabledTruncatedInputs;
			setIsLocationsLoading(true);

			locationsDebounceHandler((value: string): void => {
				let locationsList: ListInterface[] = [
					{
						title: '',
						items: [],
					},
				];
				try {
					const wordLimit: number = 3;
					if (value.length >= wordLimit) {
						void (async (tempValue: string) => {
							const tempLocations: HotelsSearchLocationsInterface[] | null = await hotelsService.getLocationsByWords(tempValue);
							const mapLocationsList: ListItemInterface[] = HotelsUtil.mapLocationsList(
								tempLocations || [],
								isSecondaryTemplate,
								isEnabledTruncatedInputs,
							);

							locationsList = [
								{
									title: '',
									items: mapLocationsList,
								},
							];

							setLocations([...locationsList]);

							setIsLocationsLoading(false);
						})(value);

						return;
					}
				} catch (error) {
					console.error(error);
				}
				setLocations([...locationsList]);

				setIsLocationsLoading(false);
			}, 300)(locationName);
		},
		[hotelsService, locationsDebounceHandler, agency],
	);

	useEffect(() => {
		const tempHistory: HotelsSearchFormSubmitInterface | null = hotelsService.getHotelsSearchFormHistory(t);
		if (tempHistory?.destinationIata && (!tempHistory?.destination || tempHistory?.destination === tempHistory?.destinationIata)) {
			try {
				void (async (tempValue: string) => {
					const tempLocations: HotelsSearchLocationsInterface[] | null = await hotelsService.getLocationsByWords(tempValue);
					const historyLocation: HotelsSearchLocationsInterface | undefined = tempLocations?.find(
						(location: HotelsSearchLocationsInterface) => location?.iata === tempValue,
					);

					if (historyLocation != null) {
						tempHistory.destination = `${historyLocation?.city || ''} - ${historyLocation?.country} (${historyLocation?.iata})`;
					}

					setHistory({ ...tempHistory });
				})(tempHistory.destinationIata);
			} catch (error) {
				console.error(error);

				setHistory(tempHistory ? { ...tempHistory } : null);
			}
		} else {
			setHistory(tempHistory != null ? { ...tempHistory } : null);
		}
	}, [hotelsService, t]);

	const isProviderSabre = (): boolean => {
		return !!agency?.providers?.hotel?.find(item => item === HotelProvider.sabre);
	};

	useEffect(() => {
		if (agency?.widgetStyles?.isSecondaryTemplate) {
			setLocations([
				{
					title: '',
					items: [],
				},
			]);
		} else {
			setLocations([
				{
					title: '',
					items: [],
				},
			]);
		}
	}, [agency?.widgetStyles?.isSecondaryTemplate, t]);

	return (
		<div className='flex w-full'>
			<Suspense fallback={<Spinner />}>
				<HotelsSearchForm
					colorPrimary={agency?.lookAndFeel?.style?.primaryColor || '#000000'}
					colorSecondary={agency?.lookAndFeel?.style?.secondaryColor}
					traductions={t}
					cities={locations}
					isCitiesLoading={isLocationsLoading}
					calendarRestriction={calendarRestriction}
					passengersRestrictions={peopleByRoomRestrictions}
					roomsRestriction={roomsRestrictions}
					history={history}
					emitSubmitSearch={searchHandler}
					emitCitiesSearch={callLocationsByWord}
					emitShowMobileFields={callShowMobileFields}
					maxRooms={maxRoomsRestrictions}
					maxGuestsPerRoom={maxGuestsPerRoom}
					datesMessage={isProviderSabre() ? t('datesMessage') : ''}
					countries={countriesList}
					displayFilterField={!isProviderSabre()}
					defaultOption={defaultNationality}
					showMobileIataField={!isProviderSabre()}
					widgetStyles={agency?.widgetStyles}
				/>
			</Suspense>
		</div>
	);
}

export default HotelsSearch;
