import { HotelsDisneySearchFormSubmitInterface } from '@smartlinks/react-design-system/dist/src/lib/hotels-disney/shared/interfaces/hotels-disney-search-form.interface';
import { RoomsFieldsRestrictionsInterface } from '@smartlinks/react-design-system/dist/src/lib/hotels/shared/interfaces/hotels-search-form.interface';
import { HotelsDisneySearchFormUtil } from '@smartlinks/react-design-system/dist/src/lib/hotels-disney/shared/utils/hotels-disney-search-form.util';
import { PassengersFieldRestrictionsInterface } from '@smartlinks/react-design-system/dist/src/shared/interfaces/generics/search-fields.interface';
import { HotelsDisneySearchWidgetProps } from '../../widgets/hotels-disney-search/hotelsDisneySearchWidget';
import { Spinner } from '@smartlinks/react-design-system/dist/src/lib/ui-elements/spinner/spinner';
import { HotelsDisneyService } from '../../shared/services/hotels-disney.service';
import { HotelsDisneyUtil } from '../../shared/utils/hotels-disney-search.util';
import { useState, useCallback, useMemo, lazy, Suspense } from 'react';
import { Agency } from '../../shared/interfaces/agency.interface';
import { endPoints } from '../../shared/end-points/end-points';
import { TFunction } from 'react-i18next';

const HotelsDisneySearchForm = lazy(async () => {
	return await import(
		'@smartlinks/react-design-system/dist/src/lib/hotels-disney/organisms/hotels-disney-search-form/hotels-disney-search-form'
	).then(module => {
		return { default: module.HotelsDisneySearchForm };
	});
});

interface HotelsDisneySearchTemplateProps extends HotelsDisneySearchWidgetProps {
	hotelsDisneyService: HotelsDisneyService;
	t: TFunction<'translation', undefined>;
}
function HotelsDisneySearch({
	agency,
	useExternalRedirect,
	hotelsDisneyService,
	t,
	callUrlSearch,
	callShowMobileFields,
}: HotelsDisneySearchTemplateProps): JSX.Element {
	const [history] = useState<HotelsDisneySearchFormSubmitInterface | null>(null);
	const maxAdults: number = 8;
	const maxChildren: number = 8;
	const peopleByRoomRestrictions: PassengersFieldRestrictionsInterface = useMemo(
		() =>
			((tempMaxAdults: number, tempMaxChildren: number): PassengersFieldRestrictionsInterface => {
				return HotelsDisneyUtil.peopleByRoomRestrictions(tempMaxAdults, tempMaxChildren);
			})(maxAdults, maxChildren),
		[maxAdults, maxChildren],
	);

	const roomsRestrictions: RoomsFieldsRestrictionsInterface = useMemo(
		() =>
			((tempMaxAdults: number, tempMaxChildren: number): RoomsFieldsRestrictionsInterface => {
				return {
					children: tempMaxChildren,
					adults: tempMaxAdults,
				};
			})(maxAdults, maxChildren),
		[maxAdults, maxChildren],
	);

	const calendarRestriction: number = useMemo(
		() =>
			((tempAgency?: Agency | null): number => {
				return HotelsDisneyUtil.calendarRestriction(tempAgency);
			})(agency),
		[agency],
	);

	const searchHandler = useCallback(
		(data: HotelsDisneySearchFormSubmitInterface): void => {
			const dataForm: HotelsDisneySearchFormSubmitInterface = {
				...data,
				urlPath: HotelsDisneySearchFormUtil.mapSearchUrl(data),
			};

			if (useExternalRedirect) {
				const agencyDomainToRedirect: string = agency?.profile?.domainUrl || '';
				const createAnchor = document.createElement('a');
				createAnchor.target = '_blank';

				createAnchor.href = endPoints.flightsSearch(agencyDomainToRedirect, dataForm?.urlPath || '');

				createAnchor.click();

				return;
			}

			if (callUrlSearch != null) {
				callUrlSearch(dataForm);
			}

			hotelsDisneyService.setLocalHistory(dataForm);
		},
		[hotelsDisneyService, useExternalRedirect, agency, callUrlSearch],
	);

	return (
		<div className='flex w-full'>
			<Suspense fallback={<Spinner />}>
				<HotelsDisneySearchForm
					colorPrimary={agency?.lookAndFeel?.style?.primaryColor || '#000000'}
					colorSecondary={agency?.lookAndFeel?.style?.secondaryColor}
					traductions={t}
					calendarRestriction={calendarRestriction}
					passengersRestrictions={peopleByRoomRestrictions}
					roomsRestriction={roomsRestrictions}
					history={history}
					emitSubmitSearch={searchHandler}
					emitShowMobileFields={callShowMobileFields}
				/>
			</Suspense>
		</div>
	);
}

export default HotelsDisneySearch;
