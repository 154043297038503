import {
	DaysRestrictionDisneySearchFormInterface,
	PassengersFieldRestrictionsDisneySearchFormInterface,
} from '@smartlinks/react-design-system/dist/src/lib/disney/shared/interfaces/disney-search-form.interface';
import { CalendarRestrictionTypes } from '../enums/calendar-restrictions.enum';
import { Agency, CalendarRestrictions } from '../interfaces/agency.interface';

export class DisneyUtil {
	public static passengersRestrictions(
		maxAdults: number,
		maxChildren: number,
		useMinimunOnePassenger?: boolean,
		defaultChilren: number = 1,
		defaultAdults: number = 1,
	): PassengersFieldRestrictionsDisneySearchFormInterface {
		return {
			maxPassengers: 20,
			infants: {
				default: 0,
				minimum: 0,
				maximum: 0,
			},
			children: {
				default: defaultChilren || 0,
				minimum: 0,
				maximum: maxChildren,
			},
			adults: {
				default: defaultAdults,
				minimum: useMinimunOnePassenger ? 0 : 1,
				maximum: maxAdults,
			},
		};
	}

	public static calendarRestriction(agency?: Agency | null): number {
		return (
			agency?.calendarRestrictions?.find((restriction: CalendarRestrictions) => restriction.product === CalendarRestrictionTypes.disney)?.value || 0
		);
	}

	public static daysRestriction(): DaysRestrictionDisneySearchFormInterface {
		return {
			min: 1,
			max: 30,
			default: 2,
		};
	}
}
