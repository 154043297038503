import { ActivitiesSearchFormSubmitInterface } from '@smartlinks/react-design-system/dist/src/lib/activities/shared/interfaces/activities-search-form.interface';
import ActivitiesSearch from '../../templates/activities-search/activities-search';
import { SearchWidgetInterface } from '../../shared/interfaces/search.interface';
import { ActivitiesService } from '../../shared/services/activities.service';
import useSearchWidget from '../../shared/hooks/useSearchWidget';
import styles from 'bundle-text:./activities-search.scss';
import { CommonsUtil } from '../../shared/utils/commons';
import { Suspense } from 'react';
import ReactDOM from 'react-dom';

export interface ActivitiesSearchWidgetProps extends SearchWidgetInterface<ActivitiesSearchFormSubmitInterface> {}

const AppActivitiesSearchWidget = ({
	token,
	language,
	useExternalRedirect,
	agency,
	callUrlSearch,
	callShowMobileFields,
}: ActivitiesSearchWidgetProps): JSX.Element => {
	const widgetClassName: string = 'activitiesSearchWidget';
	const {
		agencyState,
		isLoading,
		widgetStyles,
		widgetService: activitiesService,
		urlSearchHandler,
		showMobileFieldsHandler,
		t,
	} = useSearchWidget<ActivitiesService, ActivitiesSearchFormSubmitInterface>({
		token,
		language,
		agency,
		className: widgetClassName,
		styles: styles?.toString() || '',
		callUrlSearch,
		callShowMobileFields,
		productServiceClass: ActivitiesService,
	});

	return (
		<Suspense fallback={<div>...</div>}>
			{widgetStyles}
			<div id={agencyState?.profile?.name?.replace(/\s+/g, '') || 'noName'} className={widgetClassName}>
				{!isLoading && (
					<ActivitiesSearch
						language={language || 'es'}
						agency={agencyState}
						activitiesService={activitiesService}
						callUrlSearch={urlSearchHandler}
						callShowMobileFields={showMobileFieldsHandler}
						t={t}
					/>
				)}
			</div>
		</Suspense>
	);
};

export function ActivitiesSearchWidget(props: ActivitiesSearchWidgetProps): void {
	const element: HTMLDivElement | null = CommonsUtil.verifyWidgetElement(props?.elementId);
	if (element) {
		void CommonsUtil.createShadowRoot(element, true, true).then((renderIn: HTMLDivElement) => {
			ReactDOM.render(
				<div className='reactWidget activitiesSearch'>
					<AppActivitiesSearchWidget {...props} />
				</div>,
				renderIn,
				() => {
					if (props?.callBack) {
						props.callBack(element);
					}
				},
			);
		});
	}
}
