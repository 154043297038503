import { FlightsSearchFormSubmitInterface } from '@smartlinks/react-design-system/dist/src/lib/flights/shared/interfaces/flights-search-form.interface';
import { FlightsSearchFormUtil } from '@smartlinks/react-design-system/dist/src/lib/flights/shared/utils/flights-search-form.util';
import { FlightsSearchAirportsInterface, FlightsSearchAirlinesInterface } from '../interfaces/flights-airports.interface';
import { ResponseCommons } from '../../shared/interfaces/commons.interface';
import { LocalStorageHistoriesNames } from '../enums/local-storage.enum';
import { ResponseHttp } from '../interfaces/response-http.interface';
import { endPoints } from '../end-points/end-points';
import { CommonsService } from './commons.service';

export class FlightsService extends CommonsService {
	constructor(token: string) {
		super(token, LocalStorageHistoriesNames.flightsSearch);
	}

	public async getAirports(): Promise<FlightsSearchAirportsInterface[] | null> {
		const url: string = `${this.managementApi}/${endPoints.airports}`;
		const airportsResponse: ResponseHttp<FlightsSearchAirportsInterface[]> = await this.httpCall.get(url, this.header);
		if (airportsResponse.response != null) {
			return airportsResponse.response;
		}

		return null;
	}

	public async getAirportsByWords(value: string): Promise<FlightsSearchAirportsInterface[] | null> {
		const url: string = `${this.managementApi}/${endPoints.airportsByWords(value)}`;
		const airportsResponse: ResponseHttp<FlightsSearchAirportsInterface[]> = await this.httpCall.get(url, this.header);
		if (airportsResponse.response != null) {
			return airportsResponse.response;
		}

		return null;
	}

	public async getAirlines(): Promise<FlightsSearchAirlinesInterface[] | null> {
		const url: string = `${this.managementApi}/${endPoints.airlines}`;
		const airlinesResponse: ResponseHttp<FlightsSearchAirlinesInterface[]> = await this.httpCall.get(url, this.header);
		if (airlinesResponse.response != null) {
			return airlinesResponse.response;
		}

		return null;
	}

	public getFlightsSearchFormHistory(traductions?: (label: string) => string): FlightsSearchFormSubmitInterface | null {
		const tempHistory: ResponseCommons<FlightsSearchFormSubmitInterface> = this.getHistory<FlightsSearchFormSubmitInterface>(
			FlightsSearchFormUtil.historyHandle,
			traductions,
		);

		return tempHistory?.response || null;
	}
}
