import { DisneySearchFormSubmitInterface } from '@smartlinks/react-design-system/dist/src/lib/disney/shared/interfaces/disney-search-form.interface';
import { DisneySearchFormUtil } from '@smartlinks/react-design-system/dist/src/lib/disney/shared/utils/disney-search-form.util';
import { PassengersFieldRestrictionsInterface } from '@smartlinks/react-design-system/dist/src/shared/interfaces/generics';
import { Spinner } from '@smartlinks/react-design-system/dist/src/lib/ui-elements/spinner/spinner';
import { SpecialEventsDefaultValueProp } from '../../shared/interfaces/special-events.interface';
import { DisneySearchWidgetProps } from '../../widgets/disney-search/disneySearchWidget';
import { SpecialEventsLayout } from '../../shared/enums/special-events.enum';
import { DisneyService } from '../../shared/services/disney.service';
import { DisneyUtil } from '../../shared/utils/disney-search.util';
import { Agency } from '../../shared/interfaces/agency.interface';
import { useCallback, useMemo, lazy, Suspense } from 'react';
import { TFunction } from 'react-i18next';

const SpecialEventsSearchForm = lazy(async () => {
	return await import('@smartlinks/react-design-system/dist/src/lib/disney/organisms/special-events-search-form/special-events-search-form').then(
		module => {
			return { default: module.SpecialEventsSearchForm };
		},
	);
});

interface SpecialEventsSearchTemplateProps extends DisneySearchWidgetProps {
	layout: SpecialEventsLayout;
	defaultValue?: SpecialEventsDefaultValueProp;
	disneyService: DisneyService;
	t: TFunction<'translation', undefined>;
}

function SpecialEventsSearch({ agency, disneyService, t, callUrlSearch, layout, defaultValue }: SpecialEventsSearchTemplateProps): JSX.Element {
	const passengersRestrictions: PassengersFieldRestrictionsInterface = useMemo(
		() =>
			((tempAgency?: Agency | null): PassengersFieldRestrictionsInterface => {
				const maxDays: number = 20;
				const defaultChildren: number = 1;
				const passengers: PassengersFieldRestrictionsInterface = DisneyUtil.passengersRestrictions(maxDays, maxDays, true, defaultChildren, 0);
				if (defaultValue) {
					passengers.adults.default = defaultValue.adults;

					passengers.children.default = defaultValue.children;
				}

				return passengers;
			})(agency),
		[agency],
	);

	const history: DisneySearchFormSubmitInterface | null = useMemo(
		() =>
			((tempDisneyService: DisneyService): DisneySearchFormSubmitInterface | null => {
				return tempDisneyService.getDisneySearchFormHistory(t);
			})(disneyService),
		[disneyService, t],
	);

	const searchHandler = useCallback(
		(data: DisneySearchFormSubmitInterface): void => {
			const dataForm: DisneySearchFormSubmitInterface = {
				...data,
				urlPath: DisneySearchFormUtil.mapSpecialEventsSearchUrl(data),
			};

			if (callUrlSearch != null) {
				callUrlSearch(dataForm);
			}

			disneyService.setLocalHistory(dataForm);
		},
		[disneyService, agency, callUrlSearch],
	);

	return (
		<div className='specialEventsSearchForm flex w-full'>
			<Suspense fallback={<Spinner />}>
				<SpecialEventsSearchForm
					colorPrimary={agency?.lookAndFeel?.style?.primaryColor || '#000000'}
					colorSecondary={agency?.lookAndFeel?.style?.secondaryColor}
					traductions={t}
					passengersRestrictions={passengersRestrictions}
					history={history}
					emitSubmitSearch={searchHandler}
					layout={layout}
				/>
			</Suspense>
		</div>
	);
}

export default SpecialEventsSearch;
