{
	"elementNofound": "Elemento para renderização não encontrado",
	"byDay": "por dia",
	"coverages": "Cobertura",
	"transmission": "Transmissão",
	"BasicPlus": "Total",
	"Only": "só carro",
	"onlyCar": "só carro",
	"Basic": "Básico",
	"Full": "Combustível total",
	"thisCoverageIncludes": "Esta cobertura inclui",
	"automatic": "Automático",
	"manual": "Manual",
	"amountPassengers": "Número de passageiros",
	"amountDoors": "Número de portas",
	"airConditioning": "Ar-condicionado",
	"amountBaggage": "Número de malas",
	"theftProtectionPartial": "Seguro TP-Roubo (Parcial)",
	"collisionDamageWaiver": "Seguro de Colisão, Colisão e Roubo (CDW – TP)",
	"superCollisionDamageWaiver": "SCDW-Super Capa.",
	"personalAccidentInsurances": "PAI – Seguro de acidentes pessoais.",
	"theftProtection": "TP-Seguro contra roubo.",
	"taxes": "IMPOSTO-Impostos",
	"insurances": "Seguro",
	"lowDamageWaiver": "LDW – Seguro contra colisão e roubo",
	"liabilitySupplementalInsurance": "LIS-Danos a terceiros",
	"secondDriver": "ADDDRV-2º driver",
	"fullGas": "GÁS-Um tanque de gasolina",
	"book": "reserva",
	"dailyRateText": "Dia",
	"yes": "Sim",
	"couldNotGetAgency": "Desculpe, não foi possível obter a agência",
	"noResultsForSelectedDates": "O serviço de aluguel de automóveis não está disponível neste destino.",
	"collisionDamageWaiverPartial": "Seguro CDW-Colisão (Parcial).",
	"selectYourDestination": "Selecione seu destino",
	"dropOffLocation": "Lugar de entrega",
	"addYourDestination": "Adicione seu destino",
	"selectYourDates": "Selecione suas datas",
	"labelDates": "Datas",
	"addYourDates": "Adicione suas datas",
	"selectYourDiscount": "Selecione seu desconto",
	"withoutDiscount": "Sem desconto",
	"payOnLine": "Pague on-line",
	"discounts": "Descontos",
	"discount": "Desconto",
	"selectADiscount": "Selecione um desconto",
	"haveMyOwnDiscount": "Eu tenho meu próprio desconto",
	"promotionalCode": "Código promocional",
	"selectApromotionalCode": "Selecione um código promocional",
	"haveMyOwnPromotionalCode": "Eu tenho meu próprio código promocional",
	"hertzGold": "Hertz ouro ",
	"enterTheCodeHertzGold": "Digite seu código Hertz",
	"enterYourHertzGoldCodeHere": "Digite seu código hertz gold aqui",
	"add": "Adicionar",
	"youSaveUpToDescription": "Você economiza até 30% selecionando esta opção.",
	"paymentOnArrival": "Pagamento na chegada",
	"startYourSearch": "Comece sua pesquisa",
	"returnTheCarInTheSamePlace": "Devolva o carro no mesmo lugar",
	"searchByLocations": "Pesquise por locais",
	"selectHours": "Selecione o horário",
	"pickup": "Escolher",
	"return": "Retornar",
	"delivery": "Entrega",
	"ready": "Preparar",
	"enterADiscount": "Insira um desconto",
	"enterAPromoCode": "Insira um código promocional",
	"saveUpByAddingYourGoldHertzCode": "Economize até 30% adicionando seu código Gold Hertz",
	"next": "Seguindo",
	"back": "Voltar",
	"finalize": "Terminar",
	"getBack": "Volte",
	"viewDeal": "Ver oferta",
	"airportList": "Lista de aeroportos",
	"cityList": "Lista de cidades",
	"selectYourDelivery": "Selecione sua entrega",
	"selectYourHertzGold": "Selecione o seu Hertz Gold",
	"withoutHertzGold": "Sem Hertz Gold",
	"noHertzGold": "Não tem Hertz Gold?",
	"addItHere": "Adicione aqui",
	"select": "Selecione",
	"selectGuests": "Selecione os convidados",
	"localities": "Localizações",
	"localittiesNoFound": "Desculpe, não encontramos nenhum resultado. ",
	"results": "Resultados",
	"selectYourDiscounts": "Selecione seus descontos",
	"weSorry": "Sentimos muito",
	"search": "Procurar",
	"returnTheCarInOtherPlace": "Devolva o carro em outro local",
	"first": "Primeiro",
	"economic": "Econômico",
	"business": "Negócios",
	"economy": "Turista",
	"premiumEconomy": "Economia Premium",
	"premiumFirst": "Primeiro Prêmio",
	"premiumBusiness": "Executivo Premium",
	"allClassesLabel": "Todas as aulas",
	"origin": "Origem",
	"destination": "Destino",
	"passengersAndClass": "Passageiros",
	"selectTheClass": "Escolha a turma",
	"adult": "Adulto",
	"adults": "Adultos",
	"adultsDescription": "Mais de 12 anos",
	"child": "Menino",
	"children": "Crianças",
	"childrenDescription": "De 2 a 11 anos",
	"infant": "Infantil",
	"infants": "Bebês",
	"infantsDescription": "Crianças menores de 2 anos",
	"all": "Todos",
	"comeBack": "Retornar",
	"roundTrip": "Ida e volta",
	"onewayTrip": "Somente ida",
	"multiTrip": "Multi-destino",
	"airline": "CIA aérea",
	"searchTheAirline": "Encontre a companhia aérea",
	"onlyDirectFlights": "Apenas voos diretos",
	"addFlight": "Adicionar rota",
	"removeFlight": "Remover caminho",
	"startYourFlightsSearch": "Comece sua pesquisa",
	"ageOfChild": "Idade do Menor",
	"adultsAndChildren": "Adultos e Menores",
	"room": "Sala",
	"removeRoom": "Excluir sala",
	"addRoom": "Adicionar quarto",
	"destinationsList": "Lista de destinos",
	"selectYourDate": "Selecione sua data",
	"passengers": "Passageiros",
	"passenger": "Passageiro",
	"ageOfPassenger": "Idade do passageiro",
	"startYourHotelsSearch": "Comece sua pesquisa",
	"startYourActivitiesSearch": "Comece sua pesquisa",
	"howManyDays": "Quantos dias nos parques?",
	"withPromoCode": "Eu tenho um código promocional",
	"promoCode": "Código promocional",
	"errorDateMinFlightMulti": "A data deve ser maior que a anterior",
	"freeEntranceMessage": "Entrada gratuita para crianças menores de 3 anos (ao entrar no parque).",
	"adultsDisneyDescription": "Mais de 10 anos",
	"childrenDisneyDescription": "Entre 3 e 9 anos",
	"infantDisneyDescription": "Crianças menores de 3 anos entram gratuitamente no parque",
	"Mundial": "mundo",
	"Domestic": "Nacional",
	"LATAM": "América Latina",
	"USA": "Estados Unidos",
	"EU": "Europa",
	"childOrAdult": "jovem ou adulto",
	"childrenOrAdults": "Jovem ou velho",
	"childrenAndAdults": "Jovens e adultos",
	"olderAdult": "Idoso",
	"olderAdults": "Adultos mais velhos",
	"olderAdultsDescription": "Entre 75 e 85 anos",
	"childrenAndAdultsDescription": "Entre 0 e 74 anos",
	"annualCoverage": "Cobertura anual",
	"datesMessage": "Selecione uma estadia de no máximo 28 dias.",
	"parks": "Parque",
	"allParks": "Todos os parques (Orlando)",
	"checkIn": "Data de admissão",
	"checkOut": "Data de aposentadoria",
	"one": "1",
	"two": "2",
	"addYourTicketsToPackages": "Adicione seus ingressos para o parque",
	"selectDate": "Selecione a data do seu ingresso para saber o preço total.",
	"days": "Dias",
	"improveTicket": "Melhore seu tipo de entrada",
	"VisitMultipleParks": "Visite um parque ou vários parques por dia, vários parques com mais diversão!",
	"entrance": "Entrada",
	"price": "Preço",
	"nights": "Noites",
	"plusSign": "",
	"packages": "pacotes",
	"of": "de",
	"taxesFees": "Impostos e taxas: Incluídos",
	"disney": "Disney",
	"reservationDetails": "Detalhes da reserva",
	"reserve": "reserva",
	"addonsAlert": "Desculpe, no momento não temos parques disponíveis para sua seleção; ",
	"selectYourNationality": "Selecione sua nacionalidade",
	"country": "País",
	"searchYourCountry": "Pesquise seu país",
	"startDisneyExperience": "Comece sua experiência Disney",
	"buyYourStandardTickets": "Compre seus ingressos padrão ou escolha",
	"moreOptionsToEnjoy": "mais opções para aproveitar sua estadia",
	"theMoreDaysYouChoose": "Quanto mais dias você escolher, menos pagará por dia.",
	"rememberCheckAvailabilityParks": "Lembre-se de verificar a disponibilidade dos parques antes de comprar",
	"seeCalendar": "Ver calendário",
	"class": "Aula",
	"passengersAndAirlines": "Passageiros",
	"optionalLabel": "Opcional",
	"requiredField": "Preencha este campo",
	"cancel": "Cancelar",
	"apply": "Aplicar",
	"selectAnOrigin": "Selecione uma origem",
	"selectOrigin": "Selecione origem",
	"selectADestination": "Selecione um destino",
	"selectDestination": "Selecione destino",
	"selectAnOption": "Selecione uma opção",
	"points": "Pontos",
	"consultMyPoints": "Confira meus pontos",
	"inputYourData": "Insira seus dados",
	"bacCreditCard": "Cartão de crédito BAC",
	"forConsultingPoints": "para ver quantos pontos ou milhas você acumulou.",
	"cardHolderName": "nome do titular",
	"cardNumber": "Número de cartão",
	"expirationDate": "Data de expiração",
	"cvc": "CVV",
	"consulting": "Consultando...",
	"consult": "Consultar",
	"availableKey": "disponíveis",
	"toConsultPoints": "Para verificar seus pontos ou milhas,",
	"mustEnterData": "você deve inserir um cartão de crédito BAC.",
	"consultedCreditCard": "Cartão de crédito consultado",
	"close": "Fechar",
	"consultAnotherCard": "Consulte outro cartão",
	"carsDisclaimer": "De momento este serviço não está disponível para destinos nacionais",
	"ticketsForSpecialEvents": "Ingressos para eventos especiais",
	"buyNow": "Comprar agora",
	"selectYourPassengers": "Selecione passageiros",
	"verifyInformation": "Para continuar, verifique as seguintes informações:",
	"verifyStatusCard": "• Valide o status do seu cartão.",
	"validateCardAffiliated": "• Verifique se o seu cartão está afiliado a um plano de fidelidade de pontos ou milhas.",
	"checkCardAgain": "Verifique novamente o seu cartão ",
	"contactCustomer": "ou entre em contato com o atendimento ao cliente do BAC para mais informações.",
	"unableVerifyCard": "Não conseguimos consultar o cartão inserido",
	"guests": "Convidados",
	"departingDate": "Data de partida",
	"hour": "Hora",
	"deliveryDate": "Data de entrega",
	"pickupDate": "Data de retirada",
	"cities": "Cidades",
	"airports": "Aeroportos",
	"seeAllLocations": "Ver todas as localizações",
	"selectPickup": "Selecione a retirada",
	"selectReturn": "Selecione o retorno",
	"selectPluralDates": "Selecione as datas",
	"selectPassengers": "Seleccionar pasajeros",
	"visitors": "Visitantes",
	"age": "Edad",
	"childrenLessOneYearMsg": "Los bebés menores de un año no es necesario registrarlos.",
	"adultsDescriptionHotels": "Igual o mayores de 18 años",
	"childrenDescriptionHotles": "de 1 a 17 años",
	"pluralOverEighteenYears": "Mayores de 18 años",
	"pluralBaby": "Bebés",
	"singularBaby": "Bebé",
	"sinceTwelveYears": "Desde 12 años",
	"rooms":"Habitaciones"
}
