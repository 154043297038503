import { AssistancesSearchFormSubmitInterface } from '@smartlinks/react-design-system/dist/src/lib/assistances/shared/interfaces/assistances-search-form.interface';
import { AssistancesSearchFormUtil } from '@smartlinks/react-design-system/dist/src/lib/assistances/shared/utils/assistances-search-form.util';
import { AssistanceDestinations } from '../interfaces/assistance-destinations.interface';
import { LocalStorageHistoriesNames } from '../enums/local-storage.enum';
import { ResponseCommons } from '../interfaces/commons.interface';
import { CommonsService } from './commons.service';

export class AssistancesService extends CommonsService {
	constructor(token: string) {
		super(token, LocalStorageHistoriesNames.assistancesSearch);
	}

	public getLocations(traduction: (value: string) => string): AssistanceDestinations[] {
		const locationsResponse: AssistanceDestinations[] = [
			{
				name: traduction('Mundial'),
				code: 'Mundial',
				description: traduction('Mundial'),
			},
			{
				name: traduction('Domestic'),
				code: 'Domestic',
				description: traduction('Domestic'),
			},
			{
				name: traduction('LATAM'),
				code: 'LATAM',
				description: traduction('LATAM'),
			},
			{
				name: traduction('USA'),
				code: 'USA',
				description: traduction('USA'),
			},
			{
				name: traduction('EU'),
				code: 'EU',
				description: traduction('EU'),
			},
		];

		return locationsResponse;
	}

	public getAssistancesSearchFormHistory(traductions?: (label: string) => string): AssistancesSearchFormSubmitInterface | null {
		const tempHistory: ResponseCommons<AssistancesSearchFormSubmitInterface> = this.getHistory<AssistancesSearchFormSubmitInterface>(
			AssistancesSearchFormUtil.historyHandle,
			traductions,
		);

		return tempHistory?.response || null;
	}
}
