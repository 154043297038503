import { AssistancesSearchFormSubmitInterface } from '@smartlinks/react-design-system/dist/src/lib/assistances/shared/interfaces/assistances-search-form.interface';
import { ListInterface, ListItemInterface } from '@smartlinks/react-design-system/dist/src/shared/interfaces/list.interface';
import { AssistanceDestinations } from '../../shared/interfaces/assistance-destinations.interface';
import AssistancesSearch from '../../templates/assistances-search/assistances-search';
import { SearchWidgetInterface } from '../../shared/interfaces/search.interface';
import { AssistancesService } from '../../shared/services/assistances.service';
import { AssistancesUtil } from '../../shared/utils/assistances-search.util';
import useSearchWidget from '../../shared/hooks/useSearchWidget';
import { CommonsUtil } from '../../shared/utils/commons';
import { useEffect, useState, Suspense } from 'react';
import ReactDOM from 'react-dom';

export interface AssistancesSearchWidgetProps extends SearchWidgetInterface<AssistancesSearchFormSubmitInterface> {}

const AppAssistancesSearchWidget = ({
	token,
	language,
	useExternalRedirect,
	agency,
	callUrlSearch,
	callShowMobileFields,
}: AssistancesSearchWidgetProps): JSX.Element => {
	const widgetClassName: string = 'assistancesSearchWidget';
	const {
		agencyState,
		isLoading,
		widgetStyles,
		widgetService: assistancesService,
		urlSearchHandler,
		showMobileFieldsHandler,
		t,
	} = useSearchWidget<AssistancesService, AssistancesSearchFormSubmitInterface>({
		token,
		language,
		agency,
		className: widgetClassName,
		callUrlSearch,
		callShowMobileFields,
		productServiceClass: AssistancesService,
	});

	const [locations, setLocations] = useState<ListInterface[]>([]);
	useEffect(() => {
		let locationsList: ListInterface[] = [
			{
				title: (
					<span className='assistanceIco destinationTitle pl-5'>
						<span className='px-3 pl-title'>{t('destinationsList')}</span>
					</span>
				),
				items: [],
			},
		];

		const tempLocations: AssistanceDestinations[] | null = assistancesService.getLocations(t);
		const mapLocationsList: ListItemInterface[] = [...AssistancesUtil.mapLocationsList(tempLocations)];
		locationsList = [
			{
				title: (
					<span className='assistanceIco destinationTitle pl-5'>
						<span className='px-3 pl-title'>{t('destinationsList')}</span>
					</span>
				),
				items: mapLocationsList,
			},
		];

		setLocations([...locationsList]);
	}, [isLoading]);

	return (
		<Suspense fallback={<div>...</div>}>
			{widgetStyles}
			<div id={agencyState?.profile?.name?.replace(/\s+/g, '') || 'noName'} className={widgetClassName}>
				{!isLoading && (
					<AssistancesSearch
						language={language || 'es'}
						agency={agencyState}
						locations={locations}
						assistancesService={assistancesService}
						callUrlSearch={urlSearchHandler}
						t={t}
						useExternalRedirect={useExternalRedirect || false}
						callShowMobileFields={showMobileFieldsHandler}
					/>
				)}
			</div>
		</Suspense>
	);
};

export function AssistancesSearchWidget(props: AssistancesSearchWidgetProps): void {
	const element: HTMLDivElement | null = CommonsUtil.verifyWidgetElement(props?.elementId);
	if (element) {
		void CommonsUtil.createShadowRoot(element, true, true).then((renderIn: HTMLDivElement) => {
			ReactDOM.render(
				<div className='reactWidget assistancesSearch'>
					<AppAssistancesSearchWidget {...props} />
				</div>,
				renderIn,
				() => {
					if (props?.callBack) {
						props.callBack(element);
					}
				},
			);
		});
	}
}
