import { HotelsDisneySearchFormSubmitInterface } from '@smartlinks/react-design-system/dist/src/lib/hotels-disney/shared/interfaces/hotels-disney-search-form.interface';
import HotelsDisneySearch from '../../templates/hotels-disney-search/hotels-disney-search';
import { HotelsDisneyService } from '../../shared/services/hotels-disney.service';
import { SearchWidgetInterface } from '../../shared/interfaces/search.interface';
import useSearchWidget from '../../shared/hooks/useSearchWidget';
import styles from 'bundle-text:./hotels-disney-search.scss';
import { CommonsUtil } from '../../shared/utils/commons';
import { Suspense } from 'react';
import ReactDOM from 'react-dom';

export interface HotelsDisneySearchWidgetProps extends SearchWidgetInterface<HotelsDisneySearchFormSubmitInterface> {}

const AppHotelsDisneySearchWidget = ({
	token,
	language,
	agency,
	callUrlSearch,
	callShowMobileFields,
}: HotelsDisneySearchWidgetProps): JSX.Element => {
	const widgetClassName: string = 'hotelsDisneySearchWidget';
	const {
		agencyState,
		isLoading,
		widgetStyles,
		widgetService: hotelsService,
		urlSearchHandler,
		showMobileFieldsHandler,
		t,
	} = useSearchWidget<HotelsDisneyService, HotelsDisneySearchFormSubmitInterface>({
		token,
		language,
		agency,
		className: widgetClassName,
		styles: styles?.toString() || '',
		callUrlSearch,
		callShowMobileFields,
		productServiceClass: HotelsDisneyService,
	});

	return (
		<Suspense fallback={<div>...</div>}>
			{widgetStyles}
			<div id={agencyState?.profile?.name?.replace(/\s+/g, '') || 'noName'} className={widgetClassName}>
				{!isLoading && (
					<HotelsDisneySearch
						language={language || 'es'}
						agency={agencyState}
						hotelsDisneyService={hotelsService}
						callUrlSearch={urlSearchHandler}
						t={t}
						callShowMobileFields={showMobileFieldsHandler}
					/>
				)}
			</div>
		</Suspense>
	);
};

export function HotelsDisneySearchWidget(props: HotelsDisneySearchWidgetProps): void {
	const element: HTMLDivElement | null = CommonsUtil.verifyWidgetElement(props?.elementId);
	if (element) {
		void CommonsUtil.createShadowRoot(element, true, true).then((renderIn: HTMLDivElement) => {
			ReactDOM.render(
				<div className='reactWidget hotelsDisneySearch'>
					<AppHotelsDisneySearchWidget {...props} />
				</div>,
				renderIn,
				() => {
					if (props?.callBack) {
						props.callBack(element);
					}
				},
			);
		});
	}
}
