import { PassengersFieldRestrictionsInterface } from '@smartlinks/react-design-system/dist/src/shared/interfaces/generics/search-fields.interface';
import { CalendarRestrictionTypes } from '../enums/calendar-restrictions.enum';
import { Agency, CalendarRestrictions } from '../interfaces/agency.interface';

export class HotelsDisneyUtil {
	public static peopleByRoomRestrictions(maxAdults: number, maxChildren: number): PassengersFieldRestrictionsInterface {
		return {
			infants: {
				default: 0,
				minimum: 0,
				maximum: 0,
			},
			children: {
				default: 0,
				minimum: 0,
				maximum: maxAdults,
			},
			adults: {
				default: 1,
				minimum: 1,
				maximum: maxChildren,
			},
		};
	}

	public static calendarRestriction(agency?: Agency | null): number {
		return (
			agency?.calendarRestrictions?.find((restriction: CalendarRestrictions) => restriction.product === CalendarRestrictionTypes.hotelsDisney)
				?.value || 0
		);
	}
}
