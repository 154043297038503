import { ActivitiesSearchFormSubmitInterface } from '@smartlinks/react-design-system/dist/src/lib/activities/shared/interfaces/activities-search-form.interface';
import { ActivitiesSearchFormUtil } from '@smartlinks/react-design-system/dist/src/lib/activities/shared/utils/activities-search-form.util';
import { ActivitiesSearchLocationsInterface } from '../../shared/interfaces/activities-locations.interface';
import { ResponseCommons } from '../../shared/interfaces/commons.interface';
import { LocalStorageHistoriesNames } from '../enums/local-storage.enum';
import { ResponseHttp } from '../interfaces/response-http.interface';
import { endPoints } from '../end-points/end-points';
import { CommonsService } from './commons.service';

export class ActivitiesService extends CommonsService {
	constructor(token: string) {
		super(token, LocalStorageHistoriesNames.activitiesSearch);
	}

	public async getLocationsByWords(value: string): Promise<ActivitiesSearchLocationsInterface[] | null> {
		const url: string = `${this.managementApi}/${endPoints.activitiesLocationsByWords(value)}`;
		const locationsResponse: ResponseHttp<ActivitiesSearchLocationsInterface[]> = await this.httpCall.get(url, this.header);
		if (locationsResponse.response != null) {
			return locationsResponse.response;
		}

		return null;
	}

	public getActivitiesSearchFormHistory(traductions?: (label: string) => string): ActivitiesSearchFormSubmitInterface | null {
		const tempHistory: ResponseCommons<ActivitiesSearchFormSubmitInterface> = this.getHistory<ActivitiesSearchFormSubmitInterface>(
			ActivitiesSearchFormUtil.historyHandle,
			traductions,
		);

		return tempHistory?.response || null;
	}
}
