import { PlansSearchFormSubmitInterface } from '@smartlinks/react-design-system/dist/src/lib/plans/shared/interfaces/plans-search-form.interface';
import { PlansSearchFormUtil } from '@smartlinks/react-design-system/dist/src/lib/plans/shared/utils/plans-search-form.util';
import { OptionSelect } from '@smartlinks/react-design-system/dist/src/shared/interfaces/inputs.interface';
import { Spinner } from '@smartlinks/react-design-system/dist/src/lib/ui-elements/spinner/spinner';
import { PlansSearchWidgetProps } from '../../widgets/plans-search/plansSearchWidget';
import { PlansService } from '../../shared/services/plans.service';
import { Agency } from '../../shared/interfaces/agency.interface';
import { PlansUtil } from '../../shared/utils/plans-search.util';
import { endPoints } from '../../shared/end-points/end-points';
import { useCallback, useMemo, lazy, Suspense } from 'react';
import { TFunction } from 'react-i18next';

const PlansSearchForm = lazy(async () => {
	return await import('@smartlinks/react-design-system/dist/src/lib/plans/organisms/plans-search-form/plans-search-form').then(module => {
		return { default: module.PlansSearchForm };
	});
});

interface PlansSearchTemplateProps extends PlansSearchWidgetProps {
	categories: OptionSelect[];
	plansService: PlansService;
	t: TFunction<'translation', undefined>;
}
function PlansSearch({
	agency,
	categories,
	plansService,
	useExternalRedirect,
	t,
	callUrlSearch,
	callShowMobileFields,
}: PlansSearchTemplateProps): JSX.Element {
	const calendarRestriction: number = useMemo(
		() =>
			((tempAgency?: Agency | null): number => {
				return PlansUtil.calendarRestriction(tempAgency);
			})(agency),
		[agency],
	);

	const history: PlansSearchFormSubmitInterface | null = useMemo(
		() =>
			((tempPlansService: PlansService): PlansSearchFormSubmitInterface | null => {
				return tempPlansService.getPlansSearchFormHistory(t);
			})(plansService),
		[plansService, t],
	);

	const searchHandler = useCallback(
		(data: PlansSearchFormSubmitInterface): void => {
			const dataForm: PlansSearchFormSubmitInterface = {
				...data,
				urlPath: PlansSearchFormUtil.mapSearchUrl(data),
			};

			if (useExternalRedirect) {
				const agencyDomainToRedirect: string = agency?.profile?.domainUrl || '';
				const createAnchor = document.createElement('a');
				createAnchor.target = '_blank';

				createAnchor.href = endPoints.flightsSearch(agencyDomainToRedirect, dataForm?.urlPath || '');

				createAnchor.click();

				return;
			}

			if (callUrlSearch != null) {
				callUrlSearch(dataForm);
			}

			plansService.setLocalHistory(dataForm);
		},
		[plansService, useExternalRedirect, agency, callUrlSearch],
	);

	return (
		<div className='flex w-full'>
			<Suspense fallback={<Spinner />}>
				<PlansSearchForm
					colorPrimary={agency?.lookAndFeel?.style?.primaryColor || '#000000'}
					colorSecondary={agency?.lookAndFeel?.style?.secondaryColor}
					traductions={t}
					categories={categories}
					calendarRestriction={calendarRestriction}
					history={history}
					emitSubmitSearch={searchHandler}
					emitShowMobileFields={callShowMobileFields}
				/>
			</Suspense>
		</div>
	);
}

export default PlansSearch;
