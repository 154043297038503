import {
	CarSearchFormSubmit,
	DisabledPaymentTypeCarsSearchFormInterface,
} from '@smartlinks/react-design-system/dist/src/lib/cars/shared/interfaces/car-search-form.interface';
import { CarSearchFormLayouts } from '@smartlinks/react-design-system/dist/src/shared/enums/widgets-layouts.enum';
import { OptionSelect } from '@smartlinks/react-design-system/dist/src/shared/interfaces/inputs.interface';
import { SearchWidgetInterface } from '../../shared/interfaces/search.interface';
import { CorporateDiscounts } from '../../shared/interfaces/discounts.interface';
import { EventsWindowCustom } from '../../shared/enums/events-window.enum';
import useSearchWidget from '../../shared/hooks/useSearchWidget';
import { CarService } from '../../shared/services/cars.service';
import CarSearch from '../../templates/cars-search/cars-search';
import { CarUtil } from '../../shared/utils/car-search.util';
import { CommonsUtil } from '../../shared/utils/commons';
import { useEffect, useState, Suspense } from 'react';
import styles from 'bundle-text:./cars-search.scss';
import ReactDOM from 'react-dom';

export interface CarsSearchWidgetProps extends SearchWidgetInterface<CarSearchFormSubmit> {
	disabledPaymentType?: DisabledPaymentTypeCarsSearchFormInterface;
	callBackOpenOptions?: (element: boolean) => void;
	layout?: string | CarSearchFormLayouts;
	isPathPaymentType?: boolean;
	isNotHome?: boolean;
	refresh?: boolean;
}

const AppCarsSearchWidget = ({
	elementId,
	token,
	language,
	useExternalRedirect,
	agency,
	layout,
	isPathPaymentType,
	disabledPaymentType,
	callBackOpenOptions,
	callUrlSearch,
	callShowMobileFields,
	isNotHome,
	refresh,
}: CarsSearchWidgetProps): JSX.Element => {
	const widgetClassName: string = 'carsSearchWidget';
	const {
		agencyState,
		isLoading,
		widgetService: carsService,
		widgetStyles,
		urlSearchHandler,
		showMobileFieldsHandler,
		t,
	} = useSearchWidget<CarService, CarSearchFormSubmit>({
		className: widgetClassName,
		styles: styles?.toString() || '',
		token,
		language,
		agency,
		callUrlSearch,
		callShowMobileFields,
		productServiceClass: CarService,
	});

	const [corporateDiscounts, setCorporateDiscounts] = useState<OptionSelect[]>([]);
	const [promoCodesWithoutCorporateDiscountCode, setPromoCodesWithoutCorporateDiscountCode] = useState<OptionSelect[]>([]);
	const [corporateDiscountDefault, setCorporateDiscountDefault] = useState<OptionSelect>();
	const [promoCodeWithoutCorporateDiscountCodeDefault, setPromoCodeWithoutCorporateDiscountCodeDefault] = useState<OptionSelect>();
	const [languageCurrency, setLanguageCurrency] = useState(language);
	const carSearchFormLayout: CarSearchFormLayouts =
		layout && Object.values(CarSearchFormLayouts).includes(layout as unknown as CarSearchFormLayouts)
			? (layout as unknown as CarSearchFormLayouts)
			: CarSearchFormLayouts.bar;

	useEffect(() => {
		if (!isLoading && !(agencyState == null) && !(Object.keys(agencyState).length === 0)) {
			((): void => {
				try {
					void (async () => {
						const corporateDiscounts: CorporateDiscounts[] = await carsService.getCorporateDiscounts();
						const corporateDiscountsWithCode: CorporateDiscounts[] = [];
						const corporateDiscountsWithCodeDefault: CorporateDiscounts[] = [];
						const promoCodesWithoutCorporateDiscountCode: CorporateDiscounts[] = [];
						const promoCodesWithoutCorporateDiscountCodeDefault: CorporateDiscounts[] = [];
						corporateDiscounts.forEach((corporateDiscounts: CorporateDiscounts) => {
							if (corporateDiscounts.code && corporateDiscounts.label) {
								corporateDiscountsWithCode.push(corporateDiscounts);

								if (corporateDiscounts.isDefault) {
									corporateDiscountsWithCodeDefault.push(corporateDiscounts);
								}
							} else {
								promoCodesWithoutCorporateDiscountCode.push(corporateDiscounts);

								if (corporateDiscounts.isDefault) {
									promoCodesWithoutCorporateDiscountCodeDefault.push(corporateDiscounts);
								}
							}
						});

						setCorporateDiscounts([...CarUtil.mapCorporateDiscounts(corporateDiscountsWithCode)]);

						setPromoCodesWithoutCorporateDiscountCode([...CarUtil.mapPromocodesWithoutCorporateDiscount(promoCodesWithoutCorporateDiscountCode)]);

						setCorporateDiscountDefault(CarUtil.mapCorporateDiscountDefault(corporateDiscountsWithCodeDefault));

						setPromoCodeWithoutCorporateDiscountCodeDefault(
							CarUtil.mapPromocodesWithoutCorporateDiscountDefault(promoCodesWithoutCorporateDiscountCodeDefault),
						);
					})();

					return;
				} catch (error) {
					console.error(error);
				}
				setCorporateDiscounts([]);
			})();
		}
	}, [isLoading]);

	useEffect(() => {
		window.addEventListener(EventsWindowCustom.languageCurrencyChanged, (event: Event) => {
			const { language } = (event as CustomEvent).detail;
			if (language) {
				setLanguageCurrency(language);
			}
		});

		return () => {
			window.removeEventListener(EventsWindowCustom.languageCurrencyChanged, () => null);
		};
	}, []);

	return (
		<Suspense fallback={<div>...</div>}>
			{widgetStyles}
			{!isLoading && (
				<div id={agencyState?.profile?.name?.replace(/\s+/g, '') || 'noName'} className={widgetClassName}>
					<CarSearch
						elementId={elementId}
						corporateDiscounts={corporateDiscounts}
						corporateDiscountDefault={corporateDiscountDefault}
						promoCodesWithoutCorporateDiscountCode={promoCodesWithoutCorporateDiscountCode}
						promoCodesWithoutCorporateDiscountCodeDefault={promoCodeWithoutCorporateDiscountCodeDefault}
						language={languageCurrency}
						agency={agencyState}
						layout={carSearchFormLayout}
						isPathPaymentType={isPathPaymentType}
						disabledPaymentType={disabledPaymentType}
						callUrlSearch={urlSearchHandler}
						callBackOpenOptions={flag => {
							if (callBackOpenOptions != null) {
								callBackOpenOptions(flag);
							}
						}}
						callShowMobileFields={showMobileFieldsHandler}
						t={t}
						carsService={carsService}
						isNotHome={isNotHome}
					/>
				</div>
			)}
		</Suspense>
	);
};

export function CarsSearchWidget(props: CarsSearchWidgetProps): void {
	const element: HTMLDivElement | null = CommonsUtil.verifyWidgetElement(props?.elementId);
	const render = (renderIn: HTMLDivElement): void => {
		ReactDOM.render(
			<div className='reactWidget carSearch'>
				<AppCarsSearchWidget {...props} />
			</div>,
			renderIn,
			() => {
				if (props.callBack) {
					props.callBack(element);
				}
			},
		);
	};

	if (props.refresh && element) {
		render(element);
	}

	if (element && !props.refresh) {
		void CommonsUtil.createShadowRoot(element, true, true).then((renderIn: HTMLDivElement) => {
			render(renderIn);
		});
	}
}
