import { OptionSelect } from '@smartlinks/react-design-system/dist/src/shared/interfaces/inputs.interface';
import { CalendarRestrictionTypes } from '../enums/calendar-restrictions.enum';
import { Agency, CalendarRestrictions } from '../interfaces/agency.interface';

export class PlansUtil {
	public static calendarRestriction(agency?: Agency | null): number {
		return (
			agency?.calendarRestrictions?.find((restriction: CalendarRestrictions) => restriction.product === CalendarRestrictionTypes.plans)?.value || 0
		);
	}

	public static mapCategoriesOptions(categories: any[]): OptionSelect[] {
		const options: OptionSelect[] = [
			{
				label: 'all',
				value: 'all',
			},
		];

		categories.forEach((category: any) => {
			options.push({
				label: category?.name || '',
				value: category?.value || '',
			});
		});

		return [...options];
	}
}
