{
	"elementNofound": "Element for render no found",
	"byDay": "By day",
	"coverages": "Coverages",
	"transmission": "Transmission",
	"BasicPlus": "Total price",
	"Only": "Only car",
	"onlyCar": "Only car",
	"Basic": "Basic",
	"Full": "Total price + Fuel",
	"thisCoverageIncludes": "This coverage includes",
	"automatic": "Automatic",
	"manual": "Manual",
	"amountPassengers": "Passengers",
	"amountDoors": "Doors",
	"airConditioning": "Air conditioning",
	"amountBaggage": "Baggages",
	"theftProtectionPartial": "TP-Theft insurance(Partial)",
	"collisionDamageWaiver": "Collision damage waiver (CDW – TP)",
	"superCollisionDamageWaiver": "SCDW-Super Cover.",
	"personalAccidentInsurances": "PAI-Personal accident insurances.",
	"theftProtection": "TP-Theft protection.",
	"taxes": "Taxes",
	"insurances": "Insurances",
	"lowDamageWaiver": "LDW-Low damage waiver",
	"liabilitySupplementalInsurance": "LIS-Liability supplemental insurance",
	"secondDriver": "ADDDRV-2° Second driver",
	"fullGas": "GAS-Full Gas",
	"book": "Book",
	"dailyRateText": "Day",
	"yes": "Yes",
	"couldNotGetAgency": "Sorry, could not get agency.",
	"noResultsForSelectedDates": "The car rental service is not available in this destination.",
	"collisionDamageWaiverPartial": "CDW-Collision damage waiver (partial).",
	"selectYourDestination": "Select your destination",
	"dropOffLocation": "Drop off location",
	"addYourDestination": "Add your destination",
	"selectYourDates": "Select your dates",
	"labelDates": "Dates",
	"addYourDates": "Add your dates",
	"selectYourDiscount": "Select your discount",
	"withoutDiscount": "Without discount",
	"payOnLine": "Pay on line",
	"discounts": "Discounts",
	"discount": "Discount",
	"selectADiscount": "Select a discount",
	"haveMyOwnDiscount": "I have my own discount",
	"promotionalCode": "Promotional code",
	"selectApromotionalCode": "Select a promotional code",
	"haveMyOwnPromotionalCode": "I have my own promotional code",
	"hertzGold": "Hertz Gold ",
	"enterTheCodeHertzGold": "Enter your hertz code",
	"enterYourHertzGoldCodeHere": "enter your hertz gold code here",
	"add": "Add",
	"youSaveUpToDescription": "You save up to 30% by selecting this option.",
	"paymentOnArrival": "Payment on arrival",
	"startYourSearch": "Start your search",
	"returnTheCarInTheSamePlace": "Return the car in same place",
	"searchByLocations": "Search by locations",
	"selectHours": "Select hours",
	"pickup": "Pickup",
	"return": "Return",
	"delivery": "Delivery",
	"ready": "Ready",
	"enterADiscount": "Enter a discount",
	"enterAPromoCode": "Enter a promo code",
	"saveUpByAddingYourGoldHertzCode": "Save up to 30% by adding your Gold Hertz code",
	"next": "Next",
	"back": "Back",
	"finalize": "Finalize",
	"getBack": "Get Back",
	"viewDeal": "View deal",
	"airportList": "Airport List",
	"cityList": "City List",
	"selectYourDelivery": "Select your delivery",
	"selectYourHertzGold": "Select your Hertz Gold",
	"withoutHertzGold": "Without Hertz Gold",
	"noHertzGold": "Don't have Hertz Gold?",
	"addItHere": "Add it here",
	"select": "Select",
	"localities": "Localities",
	"localittiesNoFound": "Sorry, we couldn't find any results. We recommend you to search again with another city.",
	"results": "Results",
	"selectYourDiscounts": "Select your discounts",
	"weSorry": "We are sorry",
	"search": "Search",
	"returnTheCarInOtherPlace": "Return the car in other place",
	"first": "First",
	"economic": "Economic",
	"business": "Business",
	"economy": "Economy",
	"premiumEconomy": "Premium Economy",
	"premiumFirst": "Premium First",
	"premiumBusiness": "Premium Business",
	"allClassesLabel": "All classes",
	"origin": "Origin",
	"destination": "Destination",
	"passengersAndClass": "Passengers and Class",
	"selectTheClass": "Select the class",
	"adult": "Adult",
	"adults": "Adults",
	"adultsDescription": "Older than 12 years",
	"child": "Child",
	"children": "Children",
	"childrenDescription": "From 2 to 11 years old",
	"infant": "Infant",
	"infants": "Infants",
	"infantsDescription": "Between 0 and 2 years",
	"all": "All",
	"comeBack": "Come back",
	"roundTrip": "Round",
	"onewayTrip": "Oneway",
	"multiTrip": "Multi",
	"airline": "Airline",
	"searchTheAirline": "Search the airline",
	"onlyDirectFlights": "Only direct flights",
	"addFlight": "Add flight",
	"removeFlight": "Remove flight",
	"startYourFlightsSearch": "Start your search",
	"ageOfChild": "Age of Child",
	"adultsAndChildren": "Adults and Children",
	"room": "Room",
	"removeRoom": "Remove Room",
	"addRoom": "Add Room",
	"destinationsList": "List of Destinations",
	"selectYourDate": "Select your date",
	"passengers": "Passengers",
	"passenger": "Passenger",
	"ageOfPassenger": "Age of passenger",
	"startYourHotelsSearch": "Start your search",
	"startYourActivitiesSearch": "Start your search",
	"howManyDays": "How many days in the parks?",
	"withPromoCode": "I have a promotional code",
	"promoCode": "Promotional code",
	"errorDateMinFlightMulti": "The date must be greater than the previous",
	"freeEntranceMessage": "Free entrance for children under 3 years (at the time of entering the park).",
	"adultsDisneyDescription": "Older than 10 years",
	"childrenDisneyDescription": "Between 3 and 9 years",
	"infantDisneyDescription": "Infants under 3 years enter the park for free",
	"Mundial": "Mundial",
	"Domestic": "National",
	"LATAM": "Latin America",
	"USA": "United States",
	"EU": "Europe",
	"childOrAdult": "Child or adults",
	"childrenOrAdults": "Children or adults",
	"childrenAndAdults": "Child and adults",
	"olderAdults": "Older adults",
	"olderAdult": "Older adult",
	"olderAdultsDescription": "Between 75 and 85 years",
	"childrenAndAdultsDescription": "Between 0 and 74 years",
	"annualCoverage": "Annual coverage",
	"datesMessage": "Select a stay of maximum 28 days.",
	"parks": "Park",
	"allParks": "All Parks (Orlando)",
	"checkIn": "Date of admission",
	"checkOut": "Retirement date",
	"one": "1",
	"two": "2",
	"addYourTicketsToPackages": "Add your tickets to parks",
	"selectDate": "Select the date of your ticket to know the total price",
	"days": "Days",
	"improveTicket": "Improve your ticket type",
	"VisitMultipleParks": "Visit one park or multiple parks per day, multiple parks with more added fun!",
	"entrance": "Entrance",
	"price": "Price",
	"nights": "Nights",
	"plusSign": "+",
	"packages": "packages",
	"of": "of",
	"taxesFees": "Taxes and fees: Included",
	"disney": "disney",
	"reservationDetails": "Reservation details",
	"reserve": "Reserve",
	"addonsAlert": "Sorry, we currently have no parks available for your selection; Please try another day to enter the park.",
	"selectYourNationality": "Select your nationality",
	"country": "Country",
	"searchYourCountry": "Search your country",
	"startDisneyExperience": "Start your Disney Experience",
	"buyYourStandardTickets": "Buy your standard tickets or choose",
	"moreOptionsToEnjoy": "more options to enjoy your stay",
	"theMoreDaysYouChoose": "The more days you choose, the less you will pay per day.",
	"rememberCheckAvailabilityParks": "Remember to check the availability of the Parks before buying",
	"seeCalendar": "See calendar",
	"class": "Class",
	"passengersAndAirlines": "Passengers & airlines",
	"optionalLabel": "Optional",
	"requiredField": "Complete this field",
	"cancel": "Cancel",
	"apply": "Apply",
	"selectAnOrigin": "Select an origin",
	"selectOrigin": "Select origin",
	"selectADestination": "Select a destination",
	"selectDestination": "Select destination",
	"selectAnOption": "Select an option",
	"maximumAgeRestrictionMessageForChildren": "The value must be equal to/less than 17",
	"messageMinimumAgeRestrictionForChildren": "The value must be equal to/greater than 1",
	"points": "Points",
	"consultMyPoints": "Consult my points",
	"inputYourData": "Enter the data of your",
	"bacCreditCard": "BAC credit card",
	"forConsultingPoints": "to check how many points or miles you have accumulated.",
	"cardHolderName": "Cardholder name",
	"cardNumber": "Card number",
	"expirationDate": "Expiration date",
	"cvc": "CVC",
	"consulting": "Consulting...",
	"consult": "Consult",
	"availableKey": "available",
	"toConsultPoints": "To check your points or miles,",
	"mustEnterData": "you must enter a BAC credit card.",
	"consultedCreditCard": "Consulted credit card",
	"close": "Close",
	"consultAnotherCard": "Consult another card",
	"carsDisclaimer": "At the moment this service is not available for national destinations",
	"ticketsForSpecialEvents": "Tickets for Special Events",
	"buyNow": "Buy now",
	"selectYourPassengers": "Select your passengers",
	"betweenOneAndSeventeenYears": "Between 1 and 17 years",
	"overEighteenYears": "Over 18 years",
	"flight": "Flight",
	"addAnotherFlight": "Add another flight",
	"searchFlights": "Search flights",
	"remove": "Remove",
	"whereTravel": "Where are you traveling to?",
	"whenTravel": "When are you travelling?",
	"travelFrom": "I travel from",
	"travelTo": "I travel to",
	"departureDate": "Departure date",
	"returnDate": "Return date",
	"verifyInformation": "To continue, please verify the following information:",
	"verifyStatusCard": "• Validate the status of your card.",
	"validateCardAffiliated": "• Verify that your card is enrolled in a points or miles loyalty program.",
	"checkCardAgain": "Check your card again ",
	"contactCustomer": "or contact BAC customer service for more information.",
	"unableVerifyCard": "We were unable to verify the entered card",
	"guests": "Guests",
	"departingDate": "Departure date",
	"hour": "Hour",
	"deliveryDate": "Delivery date",
	"pickupDate": "Pickup date",
	"cities": "Cities",
	"airports": "Airports",
	"seeAllLocations": "See all locations",
	"selectPickup": "Select pickup",
	"selectReturn": "Select return",
	"selectPluralDates": "Select dates",
	"visitors": "Visitors",
	"adultsDescriptionHotels": "Equal or over 18 years old",
	"childrenDescriptionHotles": "from 1 to 17 years",
	"selectSingularDate": "Select date",
	"age": "Age",
	"selectPassengers": "Select passengers",
	"selectGuests": "Select guests",
	"childrenLessOneYearMsg": "Babies under one year old do not need to be registered.",
	"pluralOverEighteenYears": "Over 18 years",
	"pluralBaby": "Babies",
	"singularBaby": "Baby",
	"sinceTwelveYears": "From 12 years",
	"rooms":"Rooms"
}
