import { OfficesCardinal, SearchLocationsProps } from '@smartlinks/react-design-system/dist/src/shared/interfaces/maps/static-maps.interface';
import { CarSearchFormRefHandle } from '@smartlinks/react-design-system/dist/src/lib/cars/organisms/cars/car-search-form/car-search-form';
import { CarMapOffice } from '@smartlinks/react-design-system/dist/src/lib/cars/organisms/cars/cars-map-offices/car-map-office';
import { ListInterface, ListItemInterface } from '@smartlinks/react-design-system/dist/src/shared/interfaces/list.interface';
import { CarSearchFormLayouts } from '@smartlinks/react-design-system/dist/src/shared/enums/widgets-layouts.enum';
import { OptionSelect } from '@smartlinks/react-design-system/dist/src/shared/interfaces/inputs.interface';
import {
	CarSearchFormSubmit,
	CarSearchTraductions,
	DisabledPaymentTypeCarsSearchFormInterface,
} from '@smartlinks/react-design-system/dist/src/lib/cars/shared/interfaces/car-search-form.interface';
import { Spinner } from '@smartlinks/react-design-system/dist/src/lib/ui-elements/spinner/spinner';
import { CarsLocalStorageInterface } from '../../shared/interfaces/widget-localstorage.interface';
import { useDebounce } from '@smartlinks/react-design-system/dist/src/shared/hooks/useDebounce';
import { DateLocales } from '@smartlinks/react-design-system/dist/src/shared/enums/date.enum';
import { useState, useEffect, useRef, useMemo, useCallback, Suspense, lazy } from 'react';
import { CarsSearchWidgetProps } from '../../widgets/cars-search/carsSearchWidget';
import { Agency, WidgetStyles } from '../../shared/interfaces/agency.interface';
import { SearchLocations } from '../../shared/interfaces/locations.interface';
import { CarService } from '../../shared/services/cars.service';
import { endPoints } from '../../shared/end-points/end-points';
import { CarUtil } from '../../shared/utils/car-search.util';
import { Portal } from '../common/portal/portal';
import { TFunction } from 'react-i18next';

const CarSearchForm = lazy(async () => {
	return await import('@smartlinks/react-design-system/dist/src/lib/cars/organisms/cars/car-search-form/car-search-form').then(module => {
		return { default: module.CarSearchForm };
	});
});

interface CarsSearchTemplateProps extends CarsSearchWidgetProps {
	corporateDiscounts?: OptionSelect[];
	promoCodesWithoutCorporateDiscountCode?: OptionSelect[];
	corporateDiscountDefault?: OptionSelect;
	promoCodesWithoutCorporateDiscountCodeDefault?: OptionSelect;
	isNotHome?: boolean;
	carsService: CarService;
	t: TFunction<'translation', undefined>;
}

function CarSearch({
	elementId,
	agency,
	corporateDiscounts,
	corporateDiscountDefault,
	promoCodesWithoutCorporateDiscountCode,
	promoCodesWithoutCorporateDiscountCodeDefault,
	isNotHome,
	layout,
	useExternalRedirect,
	isPathPaymentType,
	disabledPaymentType,
	carsService,
	language,
	t,
	callBackOpenOptions,
	callUrlSearch,
	callShowMobileFields,
}: CarsSearchTemplateProps): JSX.Element {
	const debounceHandler = useDebounce();
	const [destinations, setDestinations] = useState<ListInterface[]>([]);
	const [currentSearchValue, setCurrentSearchValue] = useState<string>('');
	const [openOfficeModal, setOpenOfficeModal] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [locationType, setLocationType] = useState<string>('');
	const [offices, setOffices] = useState<{
		longitude: number;
		latitude: number;
		offices: OfficesCardinal[];
	}>();

	const [cultureMatch, setCultureMatch] = useState<boolean>(false);
	const standardCategory: string = 'standard';
	const mapBoxToken: string = process.env.REACT_APP_MAPBOX_TOKEN as string;
	const ref = useRef<CarSearchFormRefHandle>(null);
	const validatedDisabledPaymentType: DisabledPaymentTypeCarsSearchFormInterface | undefined = useMemo(
		() =>
			((
				tempAgency?: Agency | null,
				tempDisabledPaymentType?: DisabledPaymentTypeCarsSearchFormInterface,
			): DisabledPaymentTypeCarsSearchFormInterface | undefined => {
				return CarUtil.mapDisabledPaymentType(tempAgency, tempDisabledPaymentType);
			})(agency, disabledPaymentType),
		[agency, disabledPaymentType],
	);

	const carHistory: CarSearchFormSubmit | null = useMemo(
		() =>
			((
				tempCarsService: CarService,
				translation?: TFunction<'translation', undefined>,
				tempIsPathPaymentType?: boolean,
				cultureMatch?: boolean,
			): CarSearchFormSubmit | null => {
				return tempCarsService.getCarsSearchFormHistory(translation, tempIsPathPaymentType, cultureMatch);
			})(carsService, t, isPathPaymentType, cultureMatch),
		[carsService, t, isPathPaymentType, cultureMatch],
	);

	const restrictionCalendarDays: number = useMemo(
		() =>
			((tempAgency?: Agency | null): number => {
				return CarUtil.calendarRestriction(tempAgency);
			})(agency),
		[agency],
	);

	const hideDropOff: boolean = useMemo(
		() =>
			((tempAgency?: Agency | null): boolean => {
				return CarUtil.hideDropOff(tempAgency);
			})(agency),
		[agency],
	);

	const traduceList: CarSearchTraductions = useMemo(
		() =>
			((translation: TFunction<'translation', undefined>): CarSearchTraductions => {
				return {
					onlinePaymentTypeTooltip: translation('payOnLine'),
					destinations: {
						parentTitle: '',
						title: translation('pickup'),
						placeHolder: translation('pickup'),
						hint: '',
						secondPlaceHolder: translation('selectPickup'),
					},
					destinationsArrival: {
						parentTitle: '',
						title: translation('return'),
						placeHolder: translation('return'),
						hint: '',
						secondPlaceHolder: translation('selectReturn'),
					},
					destinationList: {
						parentTitle: translation('returnTheCarInOtherPlace'),
						title: translation('returnTheCarInTheSamePlace'),
						placeHolder: '',
						hint: translation('seeAllLocations'),
					},
					dates: {
						parentTitle: '',
						title: translation('selectYourDates'),
						placeHolder: translation('addYourDates'),
						hint: '',
					},
					discounts: {
						parentTitle: '',
						title: translation('selectYourDiscount'),
						placeHolder: translation('discount'),
						hint: '',
					},
					discountItem: {
						parentTitle: translation('discounts'),
						title: translation('discount'),
						placeHolder: translation('selectADiscount'),
						hint: translation('haveMyOwnDiscount'),
						extraPlaceHolder: translation('enterADiscount'),
					},
					promoCode: {
						parentTitle: '',
						title: translation('promotionalCode'),
						placeHolder: translation('selectApromotionalCode'),
						hint: translation('haveMyOwnPromotionalCode'),
						extraPlaceHolder: translation('enterAPromoCode'),
					},
					hertzGold: {
						parentTitle: translation('hertzGold'),
						title: translation('enterTheCodeHertzGold'),
						placeHolder: translation('enterYourHertzGoldCodeHere'),
						hint: translation('youSaveUpToDescription'),
						extraPlaceHolder: translation('saveUpByAddingYourGoldHertzCode'),
						secondTitle: translation('selectYourHertzGold'),
						secondPlaceHolder: translation('withoutHertzGold'),
						description: translation('noHertzGold'),
						extraDescription: (
							<p className='carDiscounts__noHertzTitle my-[20px]'>
								{translation('noHertzGold')}{' '}
								<a className='carDiscounts__noHertzLink underline' href='' style={{ color: '#FDD008' }}>
									{translation('addItHere')}
								</a>
							</p>
						),
						thirdTitle: translation('selectYourDiscounts'),
					},
					typePayment: {
						parentTitle: translation('paymentOnArrival'),
						title: translation('payOnLine'),
						placeHolder: '',
						hint: '',
					},
					actionBtn: translation('add'),
					mobileTextBtn: translation('startYourSearch'),
					nextBtn: translation('next'),
					backBtn: translation('back'),
					endBtn: translation('finalize'),
					getBackBtn: translation('comeBack'),
					selectText: translation('select'),
					errorRequiredField: translation('requiredField'),
					apply: translation('apply'),
					cancel: translation('cancel'),
					carsDisclaimer: translation('carsDisclaimer'),
					datesCalendar: {
						time: translation('selectHours'),
						endTime: translation('delivery'),
						startTime: translation('pickup'),
						button: translation('selectPluralDates'),
						startDateLabel: t('pickupDate'),
						endDateLabel: t('deliveryDate'),
						startTimeLabel: t('hour'),
						endTimeLabel: t('hour'),
						timeButton: t('selectHours'),
					},
					searchText: translation('search'),
				};
			})(t),
		[t],
	);

	const searchHandler = useCallback(
		(data?: CarSearchFormSubmit): void => {
			if (data != null) {
				const arrivalLocationIata = CarUtil.hideDropOff(agency) ? data.departureLocationIata : data.arrivalLocationIata || '';
				const mapSearchUrl: string = carsService.mapSearchUrl(
					data.departureLocationIata,
					arrivalLocationIata,
					data.datesDepartureDate,
					data.datesDepartureHour,
					data.datesArrivalDate,
					data.datesArrivalHour,
					standardCategory,
					data.rewards_points,
					data.corporate_discount,
					data.promotional_code,
					data.paymentType || '',
					false,
					isPathPaymentType,
				);

				const dataForm: CarSearchFormSubmit = {
					...data,
					urlPath: mapSearchUrl,
				};

				const dataLocalStorage: CarsLocalStorageInterface = {
					dates: data.dates,
					datesArrivalDate: data.datesArrivalDate,
					datesArrivalHour: data.datesArrivalHour,
					datesDepartureDate: data.datesDepartureDate,
					datesDepartureHour: data.datesDepartureHour,
					departureLocationIata: data.departureLocationIata,
					departureLocation: data.departureLocation,
					arrivalLocation: data.arrivalLocation,
					arrivalLocationIata: data.arrivalLocationIata,
				};

				if (useExternalRedirect) {
					const agencyDomainToRedirect: string = agency?.profile?.domainUrl || '';
					const createAnchor = document.createElement('a');
					createAnchor.target = '_blank';

					createAnchor.href = endPoints.carSearch(agencyDomainToRedirect, dataForm?.urlPath || '');

					createAnchor.click();

					return;
				}

				if (callUrlSearch != null) {
					callUrlSearch(dataForm);
				}

				carsService.setLocalHistory(dataLocalStorage);
			}
		},
		[carsService, useExternalRedirect, agency, callUrlSearch, language],
	);

	const callSearchByWord = useCallback(
		(words: string, widgetStyles?: WidgetStyles): void => {
			setIsLoading(true);

			debounceHandler((value: string): void => {
				const locationsList: ListInterface[] = [];
				try {
					const wordLimit: number = 3;
					if (value.length >= wordLimit) {
						void (async (tempValue: string) => {
							const tempLocations: SearchLocations | null = await carsService.getRecommendationsByWords(tempValue);
							if (tempLocations?.airports?.length) {
								const truncateClass: string = widgetStyles?.isEnableTruncatedInputs
									? 'overflow-ellipsis overflow-hidden max-w-full whitespace-nowrap'
									: '';

								const customClassMapAirports: string = widgetStyles?.isSecondaryTemplate ? 'ml-6' : '';
								const mapDestinations: ListItemInterface[] = CarUtil.mapAirports(tempLocations.airports, customClassMapAirports, truncateClass);
								locationsList.push({
									title: (
										<span className={`${widgetStyles?.isSecondaryTemplate ? 'iconPlaneSecondary px-[14px]' : ''} destinationTitle`}>
											<span className={`${widgetStyles?.isSecondaryTemplate ? 'px-3 pl-title' : 'font-semibold'}`}>{t('airports')}:</span>
										</span>
									),
									items: mapDestinations,
								});
							}

							if (tempLocations?.officesByLocation?.length) {
								const truncateClass: string = widgetStyles?.isEnableTruncatedInputs
									? 'overflow-ellipsis overflow-hidden max-w-full whitespace-nowrap'
									: '';

								const customClassMapOffices: string = widgetStyles?.isSecondaryTemplate ? `ml-6` : ``;
								const officeCountColor: string = widgetStyles?.isSecondaryTemplate ? '' : agency?.lookAndFeel?.style?.secondaryColor ?? '';
								const mapDestinations: ListItemInterface[] = CarUtil.mapOfficesList(
									tempLocations.officesByLocation,
									t('localities'),
									officeCountColor,
									customClassMapOffices,
									truncateClass,
								);

								if (mapDestinations?.length) {
									locationsList.push({
										title: (
											<span className={`${widgetStyles?.isSecondaryTemplate ? 'iconMarkerSecondary px-[14px]' : ''} destinationTitle`}>
												<span className={`${widgetStyles?.isSecondaryTemplate ? 'px-3 pl-title' : 'font-semibold'}`}>{t('cities')}:</span>
											</span>
										),
										items: mapDestinations,
									});

									const firstItem: number = 0;
									const initLongitude = tempLocations?.officesByLocation[firstItem].locations[firstItem].longitude;
									const initLatitude = tempLocations?.officesByLocation[firstItem].locations[firstItem].latitude;
									setCurrentSearchValue(tempValue);

									setOffices({
										longitude: initLongitude,
										latitude: initLatitude,
										offices: tempLocations?.officesByLocation,
									});
								}
							}

							setDestinations([...locationsList]);

							setIsLoading(false);
						})(value);

						return;
					}
				} catch (error) {
					console.error(error);

					setDestinations([...locationsList]);
				}
				setIsLoading(false);
			}, 300)(words);
		},
		[carsService, debounceHandler],
	);

	const selectedOffice = useCallback(
		(office: SearchLocationsProps) => {
			if (ref?.current?.setDestinations) {
				ref.current?.setDestinations(locationType, office);
			}

			setOpenOfficeModal(false);
		},
		[ref, locationType],
	);

	const closeOfficeModal = useCallback(() => {
		setOpenOfficeModal(false);
	}, []);

	useEffect(() => {
		if (openOfficeModal) {
			document.body.classList.add('backDropWidget');

			document.body.style.overflow = 'hidden';
		} else {
			document.body.classList.remove('backDropWidget');

			document.body.style.overflow = 'visible';
		}
	}, [openOfficeModal]);

	useEffect(() => {
		const culturePosition: number = 1;
		const url: string = window.location.href;
		const urlFragments: string[] = new URL(url).pathname.split('/');
		if (urlFragments[culturePosition].toLowerCase() === agency?.profile.culture.toLowerCase()) {
			setCultureMatch(true);
		}
	}, [cultureMatch]);

	return (
		<div className='flex'>
			<Suspense fallback={<Spinner />}>
				<CarSearchForm
					mapBoxToken={mapBoxToken}
					destinations={destinations}
					corporateDiscounts={corporateDiscounts || []}
					corporateDiscountDefault={corporateDiscountDefault}
					promoCodesWithoutCorporateDiscountCode={promoCodesWithoutCorporateDiscountCode || []}
					promoCodeWithoutCorporateDiscountCodeDefault={promoCodesWithoutCorporateDiscountCodeDefault}
					hideDropOff={hideDropOff}
					carsInSitePayment={agency?.profile?.canApplyCarPaymentInSite || false}
					colorPrimary={agency?.lookAndFeel?.style?.primaryColor || '#000000'}
					colorSecondary={agency?.lookAndFeel?.style?.secondaryColor}
					traductions={traduceList}
					language={(language as DateLocales) || DateLocales.es}
					emitInput={(value: string | undefined) => {
						setIsLoading(true);

						callSearchByWord(value || '', agency?.widgetStyles);
					}}
					emitSubmitSearch={searchHandler}
					carHistory={carHistory}
					emitOptionActive={data => !!callBackOpenOptions && callBackOpenOptions(data)}
					calendarRestriction={restrictionCalendarDays}
					openLocalities={(locationType: string) => {
						setLocationType(locationType);

						setOpenOfficeModal(true);
					}}
					layout={layout as CarSearchFormLayouts}
					isLocationsLoading={isLoading}
					searchFormRef={ref}
					disabledPaymentType={validatedDisabledPaymentType}
					emitShowMobileFields={callShowMobileFields}
					isNotHome={isNotHome}
					widgetStyles={agency?.widgetStyles}
				/>

				{openOfficeModal && (
					<Portal portalId={`${elementId || ''}_carSearchPortal`}>
						<style
							dangerouslySetInnerHTML={{
								__html: `
			.smartLinksWidgets__portal .carMapOffice__header {
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;
			}

			.smartLinksWidgets__portal .carMapOffice__tabs {
				flex-wrap: nowrap !important;
			}

			@media (min-width: 1280px){
				.smartLinksWidgets__portal .carSearch__officeModal {
				min-width: 1200px;
				width: 1200px;
				min-height: 650px;
				height: 650px;
				top: 30px;
				left: calc(50% - 600px);
				}
			}

			@media (max-width: 768px){
				.smartLinksWidgets__portal .carMapOffice__container .searchInput {
				margin: 0 !important
				}
			}

			@media (min-width: 768px){
				.smartLinksWidgets__portal .interactiveMap {
				height: 100% !important;
				max-height: 500px !important;
				}
			}
			`,
							}}
						/>
						<div className='carSearch__officeModal fixed flex justify-center w-full h-full top-0 left-0 z-50 bg-white rounded-[10px]'>
							<CarMapOffice
								defaultSearch={currentSearchValue ?? ''}
								token={mapBoxToken}
								colorPrimary={agency?.lookAndFeel.style?.secondaryColor}
								longitude={offices?.longitude ?? 0}
								latitude={offices?.latitude ?? 0}
								officesByLocation={offices?.offices ?? []}
								mapHeight={'500'}
								t={t}
								callBackSelectedOffice={selectedOffice}
								callBackCloseContent={closeOfficeModal}
								callBackSearchFromInput={value => {
									callSearchByWord(value || '', agency?.widgetStyles);

									setOffices({
										longitude: 0,
										latitude: 0,
										offices: [],
									});

									setIsLoading(true);
								}}
								isLoading={isLoading}
								className='w-full rounded-[10px]'
								headerClassName='rounded-t-[10px]'
								widgetStyles={agency?.widgetStyles}
							/>
						</div>
					</Portal>
				)}
			</Suspense>
		</div>
	);
}

export default CarSearch;
